export function getPast90Days() {
  const today = new Date();
  const past90Days = new Date(today);
  past90Days.setDate(past90Days.getDate() - 90);
  return past90Days.toISOString().split('T')[0];
}
  
export function getYesterday() {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toISOString().split('T')[0];
}