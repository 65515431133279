<script>
import {
  AmpPlugin,
  DateTime,
  easepick,
  LockPlugin,
  PresetPlugin,
  RangePlugin
} from '@easepick/bundle';
import { getPast90Days, getYesterday } from '../utils/index';

export default {
  name: 'DatePicker',
  props: {
    startDate: {
      type: [DateTime, String],
      default: new DateTime()
    },
    endDate: {
      type: [DateTime, String],
      default: new DateTime()
    },
    maxDate: {
      type: [DateTime, String],
      default: new DateTime(getYesterday())
    },
    minDate: {
      type: [DateTime, String],
      default: new DateTime(getPast90Days())
    }
  },
  emits: ['update:startDate', 'update:endDate'],
  data() {
    return {
      datepicker: null,
      dates: null
    };
  },
  methods: {
    toLocaleDateRange(date, lang, separator, options) {
      lang = lang ?? 'it-IT';
      separator = separator ?? ' - ';
      options = options ?? {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      };
      if (!date[0] && !date[1]) return '';
      date[0] = new DateTime(date[0]) ?? new DateTime(date[1]);
      date[1] = new DateTime(date[1]) ?? new DateTime(date[0]);
      if (date[0].valueOf() == date[1].valueOf())
        return date[0].toLocaleDateString(lang, options);
      const opt = { ...options };
      if (date[0].getFullYear() == date[1].getFullYear()) {
        delete opt.year;
        if (date[0].getMonth() == date[1].getMonth()) delete opt.month;
      }
      return date[0]
        .toLocaleDateString(lang, opt)
        .concat(separator, date[1].toLocaleDateString(lang, options));
    }
  },
  watch: {
    minDate: {
      handler: function (newMinDate) {
        if (
          !this.datepicker ||
          !this.datepicker.options.LockPlugin ||
          !this.datepicker.options.AmpPlugin?.dropdown
        )
          return;
        this.datepicker.options.AmpPlugin.dropdown.minYear = newMinDate?.getFullYear();
        this.datepicker.options.LockPlugin.minDate = newMinDate;
        this.datepicker.PluginManager.reloadInstance('AmpPlugin');
        this.datepicker.PluginManager.reloadInstance('LockPlugin');
        this.datepicker.renderAll();
      },
      deep: true
    }
  },
  mounted() {
    const yesterday = new DateTime();
    yesterday.setDate(new Date().getDate() - 1);
    const easepickConfig = {
      element: this.$refs.calendar,
      lang: 'it-IT',
      calendars: 2,
      grid: 2,
      format: 'D MMM YYYY',
      responsive: true,
      zIndex: 200,
      plugins: [AmpPlugin, LockPlugin, PresetPlugin, RangePlugin],
      AmpPlugin: {
        darkMode: false,
        dropdown: {
          months: true,
          years: true,
          minYear: this.minDate?.getFullYear()
        }
      },
      LockPlugin: {
        maxDate: this.maxDate,
        minDate: this.minDate
      },
      PresetPlugin: {
        position: 'top',
        customPreset: {
          ['Ultimi 7 giorni']: [new DateTime(yesterday).subtract(7), yesterday],
          ['Ultimi 30 giorni']: [
            new DateTime(yesterday).subtract(30),
            yesterday
          ],
          ['Ultimi 6 mesi']: [new DateTime(yesterday).subtract(180), yesterday],
          ['Ultimo anno']: [new DateTime(yesterday).subtract(365), yesterday]
        }
      },
      RangePlugin: {
        startDate: new DateTime(this.startDate),
        endDate: new DateTime(this.endDate),
        locale: {
          one: 'giorno',
          other: 'giorni'
        }
      },
      css: [
        'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
        'styles/datepicker.css'
      ],
      setup: (picker) => {
        picker.on('render', (e) => {
          if (picker.options.responsive) {
            picker.ui.container
              .getElementsByTagName('main')[0]
              .getElementsByClassName('calendars')[0]
              .classList.add('responsive');
          }
          const rect = picker.options.element.getBoundingClientRect();
          const wrapper = picker.ui.wrapper.getBoundingClientRect();
          const container = picker.ui.container.getBoundingClientRect();

          let top = rect.bottom - wrapper.bottom;
          let left = rect.left - wrapper.left;

          if (typeof window !== 'undefined') {
            if (
              window.innerHeight < rect.top + container.height &&
              top - container.height >= 0
            ) {
              top = rect.top - wrapper.top - container.height;
            }

            if (
              window.innerWidth < rect.left + container.width &&
              rect.right - container.width >= 0
            ) {
              left = rect.width - container.width;
            }
          }
          picker.ui.container.style.top = `${top}px`;
          picker.ui.container.style.left = `${left}px`;
        });
        picker.on('select', (e) => {
          const { start, end } = e.detail;
          this.$emit('update:startDate', start.format('YYYY-MM-DD'));
          this.$emit('update:endDate', end.format('YYYY-MM-DD'));
          this.dates = this.toLocaleDateRange([start, end]);
          picker.gotoDate(end);
        });
      }
    };
    this.datepicker = new easepick.create(easepickConfig);
    this.dates = this.toLocaleDateRange([this.startDate, this.endDate]);
  },
  unmounted() {
    this.datepicker?.destroy();
  }
};
</script>

<template>
  <input ref="calendar" id="calendar" :value="dates" />
</template>
