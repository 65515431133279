<template>
  <base-header type="dark" class="pt-5 pt-md-6">
    <div class="row">
      <div class="col-xl-12">
        <h1 class="title pb-2" style="color: white">{{ $route.name }}</h1>
        <div class="card">
          <div class="card-body py-3 searchbar">
            <div class="search-select">
              <h6 class="text-uppercase text-muted">Città</h6>
              <select v-model="form.city">
                <option value="Milano">Milano</option>
              </select>
            </div>
            <div class="search-select">
              <h6 class="text-uppercase text-muted">Categorie</h6>
              <select v-model="form.category">
                <option value="">Tutte le Categorie</option>
                <option value="CONTRAFFAZIONE">CONTRAFFAZIONE</option>
                <option value="CORRUZIONE">CORRUZIONE</option>
                <option value="DEGRADO">DEGRADO</option>
                <option value="FURTO">FURTO</option>
                <option value="ESTORSIONE">ESTORSIONE</option>
                <option value="INCENDIO">INCENDIO</option>
                <option value="INCIDENTE">INCIDENTE</option>
                <option value="MINACCIA">MINACCIA</option>
                <option value="OMICIDIO">OMICIDIO</option>
                <option value="PROSTITUZIONE">PROSTITUZIONE</option>
                <option value="RAPINA">RAPINA</option>
                <option value="SPACCIO">SPACCIO</option>
                <option value="TERRORISMO">TERRORISMO</option>
                <option value="TRUFFA">TRUFFA</option>
                <option value="USURA">USURA</option>
                <option value="VIOLENZA">VIOLENZA</option>
              </select>
            </div>
            <div class="search-select">
              <h6 class="text-uppercase text-muted">Periodo</h6>
              <DatePicker
                class="select"
                v-model:start-date="form.start_date"
                v-model:end-date="form.end_date"
              />
            </div>
            <base-button
              class="ml-auto search-submit"
              type="primary"
              size="md"
              v-on:click="searchProducts"
              :disabled="loading"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <template v-else>Cerca</template>
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </base-header>
</template>
<script>
import { BASE_URL } from '../../api/index';
import DatePicker from '../DatePicker.vue';
import { getPast90Days, getYesterday } from '../../utils/index';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      loading: false,
      form: {
        start_date: getPast90Days(),
        end_date: getYesterday(),
        city: 'Milano',
        category: ''
      }
    };
  },
  methods: {
    searchProducts() {
      this.loading = true;
      const { city, category, start_date, end_date } = this.form;
      const categories = category ? [category] : [];
      const data = JSON.stringify({
        start_date,
        end_date,
        categories,
        city,
        frequency: 'D',
        indicatore: 'n_events_count'
      });
      this.$axios
        .post(BASE_URL + '/v1/search3/', data)
        .then((res) => {
          this.$store.commit('setData3', res.data);
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    }
  },
  mounted() {
    this.searchProducts();
  }
};
</script>
